<template>
	<v-container class="course__results"
		><v-row>
			<v-col>
				<h2 class="mt-10">
					Congratulations, you have passed
					{{ this.courseInfo.title }}.
				</h2>
				<p class="mt-5">
					Lorem ipsum dolor sit amet, consectetur adipiscing elit.
					Vestibulum tempus molestie purus ut convallis. Praesent eget
					hendrerit nunc. Etiam ut consequat erat.
				</p>

				<v-row>
					<v-col class="text-right">
						<v-btn
							v-model="answersVisibility"
							@click="collapseAll"
							class="result-button mb-10"
							>{{ buttonText }}</v-btn
						>
					</v-col>
				</v-row>

				<CourseResultsElement
					v-for="(el, index) in courseResults.results"
					:header="el.question"
					:answer="el.response"
					:id="el.id"
					:index="index"
					:isCorrect="Number(el.risk_score)"
					:key="el.id"
					:opened="opened"
				></CourseResultsElement>
			</v-col>
		</v-row>
		<v-row>
			<v-col
				><h3 class="text-center mb-10">
					{{ percentage }}% correct answers for a PASS grade.
				</h3></v-col
			>
		</v-row>
	</v-container>
</template>

<script>
import { get } from "@/util/requests/get";
import CourseResultsElement from "./CourseResultsElement.vue";
import { mapGetters } from "vuex";

export default {
	name: "CourseResults",
	components: {
		CourseResultsElement
	},
	data() {
		return {
			courseResults: null,
			courseInfo: null,
			answersVisibility: false,
			percentage: null,
			opened: false
		};
	},
	methods: {
		collapseAll() {
			this.opened === false
				? (this.opened = true)
				: (this.opened = false);
		},

		async fetchCourseResults() {
			try {
				await this.$store.dispatch("setIsLoading", true);
				const response = await get(
					`/report_results/${this.$route.query.course}`
				);

				if (response.status === 200) {
					const {
						data: { data }
					} = response;
					this.courseResults = data;
				}
			} catch (error) {
				console.error(error);
			}

			await this.$store.dispatch("setIsLoading", false);
		},

		async fetchCourseInfo() {
			await this.$store.dispatch("setIsLoading", true);

			try {
				console.log(this.courseResults.course_id);

				const courseId = await Number(this.courseResults.course_id);
				const response = await get(`/course/${courseId}`);

				const {
					data: { data }
				} = response;

				this.courseInfo = data;
			} catch (error) {
				console.error(error);
			}

			await this.$store.dispatch("setIsLoading", false);
		},

		async getCourseResults() {
			const correctAnswers = [];
			const answers = this.courseResults.results.length;

			this.courseResults.results.forEach(el => {
				if (Number(el.risk_score) === 1) {
					correctAnswers.push(el.question);
				}
			});

			this.percentage = Math.floor(
				(correctAnswers.length / answers) * 100
			);
		}
	},

	computed: {
		...mapGetters(["isLoading"]),
		buttonText() {
			return this.opened === false
				? "See all answers"
				: "Hide all answers";
		}
	},

	async created() {
		await this.fetchCourseResults();
		await this.fetchCourseInfo();
		await this.getCourseResults();
	}
};
</script>

<style></style>
